import request from "@/utils/request";

/**
 * 拍卖会
 */
export const getypActionList = async (params) =>
  request({ url: "/sys/ypAction/list", method: "GET", params });

/**
 * 拍卖专场
 */
export const getypActionSpecialList = async (params) =>
  request({ url: "/sys/ypActionSpecial/list", method: "GET", params });

/**
 * 拍品
 */
export const getypLotList = async (params) =>
  request({ url: "/sys/ypLot/list", method: "GET", params });
