<template>
  <config-item :label="label">
    <GoodsConfigList
      :value="mValue"
      @change="onChange"
      @edit="show = true"
    ></GoodsConfigList>
    <GoodsConfigChoose
      v-if="show"
      :show.sync="show"
      :value="mValue"
      @submit="replace"
    >
    </GoodsConfigChoose>
  </config-item>
</template>

<script>
import ConfigItem from "../../../components/global/ConfigItem.vue";
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaGoods",

  components: { ConfigItem },

  mixins: [schemaMixin],

  props: {
    value: {
      type: [String, Object],
      default: "",
    },
  },

  data() {
    return {
      show: false,
      mValue: "",
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mValue = newValue;
      },
    },

    mValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    onChange(item) {
      this.mValue = item;
    },

    replace(item) {
      this.mValue = item;
    },
  },
};
</script>

<style lang="scss" scoped></style>
