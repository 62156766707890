<template>
  <div>
    <el-dialog
      title="请选择跳转页面"
      :visible.sync="show"
      width="30%"
      :before-close="onBeforeClose"
    >
      <div class="tabs mb30">
        <div
          class="tab flex-center"
          :class="[value.type == tab.type ? 'tab-active' : '']"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="changeTab(tab.type)"
        >
          {{ tab.label }}
        </div>
      </div>

      <!-- 微页面 -->
      <template v-if="value.type == 'fixed'">
        <el-select
          v-model="value.id"
          placeholder="请选择要跳转的微页面"
          size="mini"
          style="width: 250px; margin: 10px 10px 0 0"
          @change="onChange(value.id)"
        >
          <el-option
            v-for="(page, i) in fixedPages"
            :key="i"
            :label="page.name"
            :value="page.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="value.id == 'auctionOrder'"
          v-model="value.status"
          placeholder="请输入页面参数"
          size="mini"
          style="width: 250px; margin: 10px 10px 0 0"
        ></el-input>
        <div @click="onShowModal('auctionHouse')">
          <el-input
            v-if="value.id == 'auctionHouse'"
            placeholder="请选择拍卖会"
            :value="value.data ? value.data.title : ''"
            size="mini"
            style="width: 250px; margin: 10px 10px 0 0"
          ></el-input>
        </div>
        <div @click="onShowModal('specialDetail')">
          <el-input
            v-if="value.id == 'specialDetail'"
            placeholder="请选择拍卖专场"
            :value="value.data ? value.data.title : ''"
            size="mini"
            style="width: 250px; margin: 10px 10px 0 0"
          ></el-input>
        </div>
        <div @click="onShowModal('auctionItemDetail')">
          <el-input
            v-if="value.id == 'auctionItemDetail'"
            placeholder="请选择拍品详情"
            :value="value.data ? value.data.title : ''"
            size="mini"
            style="width: 250px; margin: 10px 10px 0 0"
          ></el-input>
        </div>
      </template>

      <!-- 自定义页面 -->
      <template v-if="value.type == 'custom'">
        <el-select
          v-model="value.id"
          placeholder="请选择要跳转的自定义页面"
          size="mini"
          style="width: 250px"
        >
          <el-option
            v-for="(page, i) in customPages"
            :key="i"
            :label="page.name"
            :value="page.id"
          >
          </el-option>
        </el-select>
      </template>

      <!-- 站外链接 -->
      <template v-if="value.type == 'link'">
        <el-input
          v-model="value.id"
          placeholder="请输入外部h5链接地址"
          size="mini"
          style="width: 250px"
        ></el-input>
      </template>
    </el-dialog>
    <auction-config-choose
      ref="auctionConfigChooseRef"
      @submit="getSelectVal"
    ></auction-config-choose>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import AuctionConfigChoose from "../SchemaAuctionModal/AuctionConfigChoose.vue";
export default {
  name: "JumpDialog",
  inject: ["value"],
  components: {
    AuctionConfigChoose,
  },
  data() {
    return {
      show: false,
      tabs: [
        {
          label: "微页面",
          type: "fixed",
        },
        {
          label: "自定义页面",
          type: "custom",
        },
        {
          label: "站外链接",
          type: "link",
        },
        {
          label: "不跳转",
          type: undefined,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),
    customPages() {
      return this.project.pages;
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    onShowModal(e) {
      this.$nextTick(() => {
        this.$refs["auctionConfigChooseRef"].open(e);
      });
    },

    changeTab(type) {
      this.$set(this.value, "type", type);
      this.$set(this.value, "id", "");
    },
    setPageId(id) {
      this.$set(this.value, "id", id);
    },
    getSelectVal(val) {
      this.value.data = {
        id: val.id,
        title: val.title,
      };
      this.$forceUpdate();
    },
    onBeforeClose() {
      let arr = ["auctionHouse", "specialDetail", "auctionItemDetail"];
      if (arr.includes(this.value.id)) {
        if (this.value.data && this.value.data.id) {
          this.show = false;
        }else{
          this.$message.error('请选择跳转参数！')
        }
      } else {
        this.show = false;
      }
    },
    onChange(type) {
      console.log("onChange:", this.value);
      let arr = ["auctionHouse", "specialDetail", "auctionItemDetail"];
      if (arr.includes(type)) {
        this.value.data = {
          id: "",
          title: "",
        };
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;

  .tab {
    width: 100px;
    height: 30px;
    color: #979797;
    border: solid 1px #ebedf0;
    cursor: pointer;
  }

  .tab-active {
    color: $color-theme;
    border: solid 1px $color-theme;
    background: #e0edff;
  }
}
</style>
