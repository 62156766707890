/**
 * @description 类型组件对应
 */
export default {
  number: "SchemaNumber",
  string: "SchemaString",
  select: "SchemaSelect",
  switch: "SchemaSwitch",
  color: "SchemaColor",
  upload: "SchemaUpload",
  jump: "SchemaJump",
  time: "SchemaTime",
  cube: "SchemaCapCube",
  goods: "SchemaGoods",
  object: "SchemaObject",
  array: "SchemaArray",
};
