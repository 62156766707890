<template>
  <el-dialog
    title="选择分类"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="45%"
  >
    <el-table
      :data="list"
      row-key="id"
      style="width: 100%"
      max-height="300"
      :tree-props="{ children: 'children' }"
    >
      <el-table-column
        prop="title"
        label="分类名"
        align="center"
        sortable
        header-align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="handle(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getYpLotCategoryTreeList } from "@/api/goods";

export default {
  name: "GoodsConfigChoose",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // value: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  created() {
    this.getList();
  },

  data() {
    return {
      visible: false,
      selectList: [],
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.visible = this.show;
      },
    },
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    // 获取店铺商品列表
    async getList() {
      let res = await getYpLotCategoryTreeList();

      if (res.success) {
        // 筛选字段
        this.list = res.result;
      }
    },
    handle(item) {
      this.$emit("submit", item);
      this.visible = false;
    },
    // 提交
    submit() {
      this.$emit(
        "submit",
        this.selectList.map((item) => item.id)
      );
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__title {
    font-size: 14px !important;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 10px 20px 30px 20px !important;
  }
}

.wrap {
  background: #f2f2f6;
  height: 400px;
  border-radius: 5px;
}
</style>
