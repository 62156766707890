/**
 * @description: 项目配置数据
 */
// 固定页面集合
export const fixedPages = [
  // {
  //   id: "search",
  //   name: "搜索页",
  //   type: "fixed",
  // },
  {
    id: "auctionItemSearch",
    name: "搜索页",
    type: "fixed",
  },
  {
    id: "auctionOrder",
    name: "拍卖订单",
    type: "fixed",
  },
  {
    id: "securityDepositRecord",
    name: "保证金记录",
    type: "fixed",
  },
  // {
  //   id: "car",
  //   name: "购物车",
  //   type: "fixed",
  // },
  {
    id: "auction",
    name: "近期拍卖",
    type: "fixed",
  },
  {
    id: "category",
    name: "商品分类",
    type: "fixed",
  },
  {
    id: "auctionHouse",
    name: "拍卖会",
   
    type: "fixed",
  },
  {
    id: "specialDetail",
    name: "拍卖专场",
    type: "fixed",
  },
  {
    id: "auctionItemDetail",
    name: "拍品详情",
    type: "fixed",
  },
 
];

// 导航配置
export const navigation = {
  label: "导航",
  styles: {
    color: "#6495ed",
    background: "#fff",
  },
  list: [
    {
      id: "00001",
      icon: "home",
      text: "首页",
      jump: {
        type: "custom",
        id: "000000",
      },
    },
    {
      id: "00003",
      icon: "grid",
      text: "分类",
      jump: {
        type: "fixed",
        id: "category",
      },
    },
    // {
    //   id: "00004",
    //   icon: "shopping-cart",
    //   text: "购物车",
    //   jump: {
    //     type: "fixed",
    //     id: "car",
    //   },
    // },
    {
      id: "00005",
      icon: "account",
      text: "我的",
      jump: {
        type: "fixed",
        id: "my",
      },
    },
  ],
};

// 商品分组配置
export const goodsGroups = [
  {
    name: "新品上架",
    type: "0001",
    level: 1,
    child: [],
  },
  {
    name: "热销排行",
    type: "0002",
    level: 1,
    child: [],
  },
];

// 商品列表模板配置
export const listTpl = {
  model: "one",
  addIcon: "cart",
};

// 商品列表模板配置
export const categoryTpl = {
  advertising: false, // 广告位
};

// 店铺初始配置
export const rojectModel = {
  name: "",
  cover: "",
  config: {
    navigation: navigation,
    goodsGroups: goodsGroups,
    listTpl: listTpl,
    categoryTpl: categoryTpl,
    hotList: [],
    backgroundColor: "#FFFFFF",
  },
  pages: [
    // 自定义页面集合
    {
      id: "000000",
      name: "首页",
      home: true,
      componentList: [],
    },
    // 自定义页面集合
    {
      id: "my",
      name: "我的",
      type: "fixed",
      home: false,
      componentListBuyer: [], // 买家
      componentListSeller: [], // 卖家
    },
  ],
};
