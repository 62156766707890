import Vue from "vue";
import axios from "axios";
import store from "@/store";
import signMd5Utils from '@/utils/encryption/signMd5Utils';
import {
  getAuthTenantId,
} from "@/utils/auth";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
});

// service interceptor
service.interceptors.request.use((config) => {
  console.log('getAuthTenantId:',  store.getters.tenantId);
  if (store.getters.token) {
    config.headers["X-Access-Token"] = store.getters.token;
    config.headers["X-Tenant-Id"] = getAuthTenantId() || '';
    config.headers["terminal-type"] = "WEB";
    config.headers["X-TIMESTAMP"] = signMd5Utils.getTimestamp();
    config.headers["X-Sign"] = signMd5Utils.getSign(config.url, config.params);
  }

  return config;
});

// response interceptor
service.interceptors.response.use((response) => {
  return response.data;
});

export default service;
