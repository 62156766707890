<template>
  <div class="flex flex-wrap">
    <!-- <div
      v-for="(item, key) in list"
      class="flex-center h45 w45 mr10 mb10 shadow"
    >
      <img class="w-100 h-100" :src="item.cover" />
    </div> -->
    <!-- <div class="flex-center h45 w45 shadow pointer" @click="add">+</div> -->
    <el-input v-model="selectValue.title" readonly placeholder="请选择" @click.native="add"></el-input>
    <!-- <el-select
      style="height: 32px; line-height: 32px"
      v-model="selectValue"
      placeholder="请选择"
      @change="onSelectData"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsByIds } from "@/api/goods";
import { ajaxGetDictItems } from "@/api/common";

export default {
  name: "GoodsConfigList",
  props: ["value"],

  data() {
    return {
      list: [],
      options: [],
      selectValue: "",
    };
  },
  created() {
    // todo 暂时关闭分类选择
    // this.getAjaxGetDictItemsApi();
  },
  computed: {
    ...mapGetters(["project"]),
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.selectValue = newValue;
      },
    },
  },
  methods: {
    async getAjaxGetDictItemsApi() {
      let res = await ajaxGetDictItems("dataList", {});
      if (res.success) {
        this.options = res.result;
      }
    },
    onSelectData(e) {
      this.$emit("change", e);
    },
    add() {
      console.log('add:',);
      this.$emit("edit");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 32px;
}
</style>
