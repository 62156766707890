<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="45%"
  >
    <div class="search">
      <el-input
        placeholder="请输入名称查询"
        style="width: 250px; margin-right: 20px"
        v-model="keyword"
      ></el-input>
      <div>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="list"
      row-key="id"
      style="width: 100%"
      max-height="500"
      :tree-props="{ children: 'children' }"
    >
      <el-table-column
        prop="title"
        label="名称"
        align="center"
        sortable
        header-align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="handle(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.pageNo"
      >
      </el-pagination>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getypActionList,
  getypActionSpecialList,
  getypLotList,
} from "@/api/auction";

export default {
  name: "AuctionConfigChoose",
  props: {
    // value: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  data() {
    return {
      visible: false,
      selectList: [],
      list: [],
      title: "选择",
      type: "",
      keyword: "",
      formData: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    handle(item) {
      this.$emit("submit", item);
      this.visible = false;
    },
    open(type) {
      this.type = type;
      this.visible = true;
      this.commonFn(this.type);
    },
    async commonFn(type) {
      let res = null;
      let title = this.keyword ? `*${this.keyword}*` : "";
      switch (type) {
        case "auctionHouse":
          this.title = "选择拍卖会";
          res = await getypActionList({
            ...this.formData,
            title,
          });
          break;
        case "specialDetail":
          this.title = "选择拍卖专场";
          res = await getypActionSpecialList({
            ...this.formData,
            title,
          });
          break;
        case "auctionItemDetail":
          this.title = "选择拍品";
          res = await getypLotList({
            ...this.formData,
            title,
          });
          break;
      }
      if (res.success) {
        let { records, total, current } = res.result;
        // 筛选字段
        this.list = records;
        this.total = total;
        this.formData.pageNo = current;
      }
    },
    onSearch() {
      this.formData.pageNo = 1;
      this.commonFn(this.type);
    },
    /**重置 */
    onReset() {
      this.clear();
      this.commonFn(this.type);
    },
    clear() {
      this.formData = {
        pageNo: 1,
        pageSize: 10,
      };
      this.total = 0;
      this.keyword = "";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.formData.pageNo = val;
      this.commonFn(this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__title {
    font-size: 14px !important;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 10px 20px 30px 20px !important;
  }
}
.search {
  display: flex;
  justify-content: space-around;
}
.page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
