import Vue from 'vue'
const baseApi = process.env.VUE_APP_BASE_API
export default async (file) => new Promise((resolve, reject) => {
  Vue.prototype.$POST.upload(baseApi + '/sys/common/upload', ({
    file,
  }), {
    baseURL: '',
    timeout: 20000,
  }).then(res => {
    resolve(baseApi + '/'+res.message)
  }).catch(e => {
    reject(e)
  })
})
