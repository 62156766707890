/**
 * @description: 用户数据管理
 */
import {
  setAuthToken,
  getAuthToken,
  setAuthUserInfo,
  getAuthUserInfo,
  getAuthTenantId,
  setAuthTenantId,
} from "@/utils/auth";

export default {
  state: {
    tenantId: getAuthTenantId(),
    token: getAuthToken(),
    userInfo: getAuthUserInfo(),
  },
  getters: {
    tenantId: (state) => state.tenantId,
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setAuthToken(token);
    },
    setTenantId(state, id) {
      state.tenantId = id;
      setAuthTenantId(id);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      setAuthUserInfo(userInfo);
    },
    logout(state) {
      state.token = null;
      state.userInfo = null;
      state.tenantId = null;
      sessionStorage.clear();
      localStorage.clear();
    },
  },
  actions: {},
  modules: {},
};
